import React from 'react';
import { Container } from 'emotion-flex';
import { FormattedMessage } from 'react-intl';
import SEO from '../components/SEO';
import EmptyState from '../components/EmptyState';
import Link from '../components/Link';
import Button from '../components/Button';
import { withLayout } from '../components/Layout';
import { useBrowserLocale } from '../hooks';

const ButtonLink = Button.withComponent(Link);

const EmailWrongLink = () => {
  useBrowserLocale();
  return (
    <>
      <SEO title="Account restoration already confirmed" />
      <Container>
        <EmptyState
          title="Error, account restoration was not successful."
          text={
            <div css={{ textAlign: 'center' }}>
              <p>
                The restoration token expired. You have to click the
                confirmation link within 10 minutes.
              </p>
              <p>Plese try again.</p>
              <p>Error type: token expired</p>
            </div>
          }
          action={
            <ButtonLink to="https://cara.app.link/Dk8UOqildy">
              <FormattedMessage
                id="pages.email.continueToApp"
                defaultMessage="Continue to App"
              />
            </ButtonLink>
          }
          withImage
        />
      </Container>
    </>
  );
};

export default withLayout(EmailWrongLink, { isNewLayout: true });
